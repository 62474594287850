.start-date-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.start-date {
  font-size: 1.5rem;
  font-weight: bold;
  color: crimson;
  text-align: center;
}

.start-d {
  font-size: 1.9rem;
  font-weight: bold;
  color: rgb(75, 7, 20);
  margin: 20px 0 20px 130px;
  text-align: center;
}

.start-date-container > p {
  text-align: center;
  color: crimson;
  margin-left: 130px;
}

.start-date-picker {
  width: 200px;
  height: 40px;
  border-radius: 5px;
}

.date-picker-container {
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
}

.user-btn {
  margin-left: 350px;
}

.user-no-tran {
  margin-left: 240px;
  width: 500px;
}

.user-transact-btn {
  width: 290px;
  height: 40px;
  padding: 10px;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: #f72727;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 5px 5px 13px #773c3c, -5px -5px 13px #d86565;
}

.no-transact-p {
  font-size: 1.5rem;
  font-weight: bold;
  color: rgb(61, 4, 15);
  text-align: center;
  font-family: 'Gumeland', cursive;
  margin: 30px;
}

.userTrans-pre-next-cont {
  margin-left: 45%;
}

.date-picker-trans1 {
  justify-content: space-between;
  gap: 30px;
  margin-left: 3%;
}

.user-transact-btn-total1 {
  width: 200px;
  margin-left: 25px;
  height: 40px;
  padding: 10px;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: #f72727;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 5px 5px 13px #332a2a, -5px -5px 13px #353131;
}

@media only screen and (max-width: 450px) {
  .cust-tansact-container1 {
    width: 320px;
  }

  .start-date-container {
    width: 320px;
  }

  .date-picker-container {
    width: 320px;
  }

  .start-date-picker {
    width: 120px;
  }

  .user-transact-btn {
    width: 200px;
  }

  .transact-customer-container {
    font-size: 12px;
    display: flex;
    flex-direction: column;
  }

  .columns {
    font-size: 8px;
  }

  .custransactname {
    font-size: 4px;
  }

  .userTrans-pre-next-cont {
    margin-left: 25%;
  }

  .start-d {
    margin: 20px 0 20px 40px;
  }

  .start-date-container > p {
    margin-left: 30px;
  }

  .date-picker-trans1 {
    width: 300px;
    margin-left: 5%;
    gap: 5px;
  }

  .user-transact-btn-total1 {
    width: 80px;
    margin-left: 10px;
    font-size: 12px;
  }
}
