.navbar_main {
  background: #ff5d5d;
  grid-area: nav;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 30px;
  border-bottom: 1px solid lightgray;
  box-shadow: 4px 4px 10px rgba(233 222 222 /10%);
}

.nav-icon {
  display: none;
}

.nav-icon > i {
  font-size: 26px;
  color: #680606;
}

.navbar__left > a {
  margin-right: 30px;
  text-decoration: none;
  color: #f2f2f2;
  font-size: 15px;
  font-weight: 700;
}

.navbar__left .active_link {
  color: #265acc;
  padding-bottom: 12px;
}

.navbar__right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar__right > a {
  margin-left: 20px;
  text-decoration: none;
}

.navbar__right > a > i {
  color: #a5a5a5;
  font-size: 16px;
  border-radius: 50px;
  background-color: #fff;
  box-shadow: 2px 2px 5px #d9d9d9, -2px -2px -5px rgb(240, 157, 157);
  padding: 7px;
}

.navbar__right > a > img {
  width: 35px;
  height: 35px;
  box-shadow: 5px 5px 13px #ff5d5d, -5px -5px 13px #000;
}

.navbar__right > a > .fa {
  box-shadow: 5px 5px 13px #ff5d5d, -5px -5px 13px #000;
}

#logout-nav {
  color: #e60e0e;
}

@media only screen and (max-width: 978px) {
  .nav-icon {
    display: inline;
  }
}

@media only screen and (max-width: 480px) {
  .navbar__left {
    display: none;
  }
}
