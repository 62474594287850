/* stylelint-disable */

.cus-preview-main-container {
  background-color: azure;
  border-radius: 8px;
  text-align: center;
  text-decoration: none;
  height: fit-content;
  width: 59.375rem;
  margin: 20px 20px 20px 45px;
  padding: 20px;
}

.transact-customer-container-p {
  border: 2px solid crimson;
  border-radius: 8px;
  width: 55rem;
  height: fit-content;
  margin: 20px 20px 20px 15px;
}

.cus-preview-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 400px;
}

.cus-info {
  font-size: 30px;
  margin: 20px;
}

.food-item {
  text-align: center;
}

.cus-details-container {
  display: flex;
  justify-content: space-around;
  gap: 20px;
}

.cus-p-image {
  width: 300px;
  height: 250px;
  border-radius: 8px;
}

.cust-preview-btn {
  width: 400px;
  height: 80px;
  display: flex;
  justify-content: space-around;
  margin-left: auto;
  margin-right: auto;
}

.p-btn {
  margin-top: 30px;
}

@media only screen and (max-width: 450px) {
  .cus-preview-main-container {
    display: flex;
    flex-direction: column;
    width:320px;
    margin: 20px;
  }

.transact-customer-container-p {
  width: 320px;
}

.cust-preview-btn {
  width: 280px;
}

.p-btn {
  width: 120px;;
}

.cus-details-container{
  flex-direction: column;
}

.transact-customer-container-p {
  width: 250px;
}

.food-item-mobile{
  margin-top: 200px;
}

.cus-p-image {
  width: 250px;
  height: 250px;
}

.cus-info {
  font-size: 22px;
}

}



/* stylelint:enable */
