.image-blurred-edge {
  margin-left: auto;
  margin-right: auto;
  background-image: url('../assets/image/error.gif');
  width: 40%;
  height: 350px;
  border: none;
  box-shadow: 0 0 8px 8px rgba(165, 165, 156, 0.95) inset;
}

.container-error {
  margin: 20px;
}

.for-o-for-text {
  font-size: 50px;
  font-weight: 700;
  color: crimson;
  margin-bottom: 20px;
}

.text-danger {
  color: crimson;
  font-size: 20px;
  font-weight: 700;
  margin: 20px;
}

.fs-3 {
  margin: 20px;
}

.btn-error {
  display: flex;
  justify-content: center;
  align-items: center;
  color: crimson;
  border-radius: 8px;
  border: none;
  font-size: 20px;
  font-weight: bolder;
  margin-top: 20px;
  gap: 30px;
}

@media only screen and (max-width: 450px) {
  .image-blurred-edge {
    width: 100%;
  }
}
