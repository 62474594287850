.product-i-edit {
  margin-left: -1.5625rem;
}

.product-i {
  margin-left: 4.0625rem;
}

@media only screen and (max-width: 450px) {
  .editproduct {
    width: 20rem;
    margin: 1.25rem;
  }
}
