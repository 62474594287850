.loader {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-top: 12.5rem;
  margin-left: 30rem;
  margin-bottom: 2rem;
}

@media screen and (max-width: 450px) {
  .loader {
    margin: 20px;
  }
}
