/* stylelint-disable */
.cus-name {
  font-size: 1.25rem;
  font-weight: 700;
  color: #2e4a66;
  margin-right: 0.3125rem;
}

.cus-phone {
  font-size: 1.25rem;
  font-weight: 700;
  color: #0f5f08;
  margin-right: 0.3125rem;
}

.transact-customer-container1 {
  border: 2px solid crimson;
  border-radius: 0.5rem;
  width: fit-content;
  height: fit-content;
  margin: 1.25rem 1.25rem 1.25rem 9.375rem;
  background: #fff;
}

.custrans-name1 {
  display: flex;
}

.customer-container {
  margin: 1.25rem 1.25rem 1.25rem 8.125rem;
  padding: 0.25rem;
  background-color: azure;
  border-radius: 0.5rem;
  text-align: center;
  height: 3.125rem;
  color: #2e4a66;
  width: 50rem;
}

.customer-container :nth-child(even) {
  background-color: rgb(243, 152, 152);
}

.pre-next-cont {
  margin-left: 47%;
  display: flex;
}

.pre-text {
  margin: 10px 20px;
  font-size: 15px;
  font-weight: bold;
}

.pre-text > span {
  color: crimson;
  margin: 0 20px;
}



@media only screen and (max-width: 450px) {
  .transact-customer-container1 {
    margin: 1.25rem;
    width: 20rem;
  }
  .cus-name {
    font-size: 0.9375rem;
  }

  .cus-phone {
    font-size: 0.9375rem;
  }

  .customer-container > h3 {
    font-size: 0.75rem;
  }

  .customer-container {
    width: 20rem;
    margin: 1.25rem;
  }

  .pre-next-cont {
    margin-left: 25%;
}

}
/* stylelint-enable */
