.error_msg {
  color: #f72727;
  animation: blinker 1s linear infinite;
}

.info_msg {
  color: #f7e627;
  animation: blinker 1s linear infinite;
}

.success_msg {
  color: #097d33;
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
