@import url('https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quintessential&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Neonderthaw&display=swap');

#sidebar {
  background: #ece9a8;
  grid-area: sidebar;
  overflow-y: auto;
  padding: 20px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  width: 290px;
}

.sidebar__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #020509;
  margin-bottom: 30px;
  font-family: 'Quintessential', sans-serif;
}

.sidebar__img {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 20px;
}

.sidebar__title > div > img {
  width: 200px;
  height: 100px;
  object-fit: contain;
  border-radius: 8px;
  margin-left: 8px;
}

.sidebar__title > div > h1 {
  font-size: 25px;
  display: inline;
}

.sidebar__title > i {
  font-size: 18px;
  display: none;
}

.sidebar__menu > h2 {
  color: #ff1818;
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 5px;
  padding: 0 10px;
  font-weight: 700;
}

.sidebar__link {
  color: #020509;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 5px;
}

.active_menu_link {
  background: rgba(240, 4, 4, 0.3);
  color: #ee2b2b;
}

.active_menu_link a {
  color: #f82323 !important;
}

.sidebar__link > a {
  text-decoration: none;
  color: #020509;
  font-weight: 700;
  font-size: 15px;
}

.sidebar__link > i {
  margin-right: 10px;
  font-size: 12px;
}

.sidebar__logout {
  padding: 10px;
  color: #e65061;
  cursor: pointer;
}

.sidebar__logout > a {
  text-decoration: none;
  color: #e65061;
  font-weight: 700;
  text-transform: uppercase;
}

.sidebar__logout > i {
  margin-right: 10px;
  font-size: 18px;
}

.sidebar-responsive {
  display: inline !important;
  z-index: 9999 !important;
  left: 0 !important;
  position: absolute;
}

.dropdown-off {
  display: none;
}

.dropdown-on {
  display: block;
}

#toggle-btn {
  margin-left: 10px;
}

#drop-menu {
  /* width: 100%; */
  background: #ff5d5d;
  padding: 10px 0 10px 40px;
  box-shadow: 0 0 10px #000;
  list-style-type: none;
  margin-bottom: -25px;
  border-radius: 5px;
}

#drop-menu > li {
  text-decoration: none;
  color: #000;
  font-weight: 700;
  margin-bottom: 8px;
  font-size: 14px;
}

#drop-menu > li > a {
  color: #fff;
}

@media only screen and (max-width: 978px) {
  #sidebar {
    display: none;
    height: 108vh;
    padding-bottom: 1.25rem;
  }

  .sidebar__title > i {
    display: inline;
  }

  .sidebar__logout {
    padding: 0.625rem 0.625rem 1.25rem 0.625rem;
  }

  .sidebar__menu {
    padding-bottom: 2.5rem;
  }
}
