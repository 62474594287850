@import url('https://fonts.googleapis.com/css2?family=Quintessential&display=swap');

main {
  background: #fff;
  grid-area: main;
  overflow-y: auto;
}

.main__container {
  padding: 1.25rem 1rem;
}

.main__title {
  display: flex;
  align-items: center;
}

.main__title > img {
  max-height: 6.25rem;
  object-fit: contain;
  margin-right: 1.25rem;
}

.main__greetings {
  font-size: 1.5rem;
  color: #006;
  margin-bottom: 0.3125rem;
}

.main__greetings h1 {
  color: #990f0f;
  font-family: 'Quintessential', sans-serif;
}

.main__greetings > p {
  font-size: 17px;
  font-weight: 700;
  color: #a5aaad;
}

.main__cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1.875rem;
  margin: 1.25rem 0;
}

.cards {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 4.375rem;
  background: #fff;
  border-radius: 0.3125rem;
  box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #ededed;
  padding: 1.5625rem;
}

.card__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card__inner > span {
  font-size: 1.5625rem;
}

.charts {
  display: flex;
  grid-template-columns: 1fr 1fr;
  gap: 1.875rem;
  margin-top: 3.125rem;
}

.charts__inner {
  background: #fff;
  border-radius: 0.3125rem;
  box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #ededed;
  padding: 1.5625rem;
}

.charts__left__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.charts__left__title > div > h1 {
  font-size: 1.5rem;
  color: #2e4a66;
  margin-bottom: 0.3125rem;
}

.charts__left__title > div > p {
  font-size: 0.875rem;
  font-weight: 700;
  color: #a5aaad;
}

.charts__left__title > i {
  font-size: 1.25rem;
  color: #fff;
  background: #ffc100;
  border-radius: 200px 0 200px 200px;
  -moz-border-radius: 200px 0 200px 200px;
  -webkit-border-radius: 200px 0 200px 200px;
  border: 0 solid #000;
  padding: 15px;
}

.charts__right {
  background: #fff;
  border-radius: 5px;
  box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #ededed;
  padding: 25px;
}

.charts__right__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.charts__right__title > div > h1 {
  font-size: 1.5rem;
  color: #2e4a66;
  margin-bottom: 5px;
}

.charts__right__title > div > p {
  font-size: 0.875rem;
  font-weight: 700;
  color: #a5aaad;
}

.charts__right__title > i {
  font-size: 20px;
  color: #fff;
  background: #394474;
  border-radius: 200px 0 200px 200px;
  -moz-border-radius: 200px 0 200px 200px;
  -webkit-border-radius: 200px 0 200px 200px;
  border: 0 solid #000;
  padding: 15px;
}

.charts__right__cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 30px;
}

.card1 {
  background: #d1ecf1;
  color: #35a4ba;
  text-align: center;
  border-radius: 5px;
  padding: 25px;
  font-size: 14px;
}

.card2 {
  background: #d2f9ee;
  color: #38e1b0;
  text-align: center;
  border-radius: 5px;
  padding: 25px;
  font-size: 14px;
}

.card3 {
  background: #d6d8d9;
  color: #3e3e41;
  text-align: center;
  border-radius: 5px;
  padding: 25px;
  font-size: 14px;
}

.card4 {
  background: #fddcdf;
  color: #f65a6f;
  text-align: center;
  border-radius: 5px;
  padding: 25px;
  font-size: 14px;
}

.welcome-date {
  font-size: 17px;
}

@media only screen and (max-width: 320px) {
  .main__cards {
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 0;
  }

  .charts {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }

  .charts__right {
    box-sizing: border-box;
    width: fit-content;
    padding: 15px;
  }

  .h-card {
    font-size: 12px;
  }

  .charts__right__cards {
    gap: 12px;
  }
}

@media only screen and (max-width: 855px) {
  .main__cards {
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 0;
  }

  .charts {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }

  .charts__right {
    box-sizing: border-box;
    width: fit-content;
  }

  .h-card {
    font-size: 12px;
  }

  .charts__right__cards {
    gap: 15px;
  }
}
