.register-form {
  margin-top: 10px;
  padding: 20px;
}

.form2 {
  margin-bottom: 10px;
}

.form1 {
  margin-top: 15px;
  margin-bottom: 1.25rem;
  padding-bottom: 0.625rem;
  gap: 15px;
}

select {
  width: 31.25rem;
  height: 1.875rem;
  outline: none;
  box-shadow: 5px 5px 13px #773c3c, -5px -5px 13px #967777;
  border-radius: 3px;
  gap: 20px;
  cursor: pointer;
  padding-left: 5px;
}

.add-marketer-btn {
  width: 12.5rem;
  height: 3.125rem;
  padding: 10px;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: #f72727;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 5px 5px 13px #773c3c, -5px -5px 13px #d86565;
}

.mark-btn {
  margin-left: -40px;
}

#eye {
  margin: 1.6875rem 0 0 28.75rem;
  position: absolute;
}

.edit-user-btn {
  margin-left: -30px;
}

.edit-user-icon:hover {
  cursor: pointer;
  background: #fff;
  border: 1px solid #000;
  width: 50px;
  margin-left: 14.875rem;
  color: #00bcd4;
  border-radius: 4px;
}

.reg-edit {
  text-align: center;
}

.upload-img {
  width: 12.5rem;
}

@media only screen and (max-width: 450px) {
  .register-form {
    margin-left: 10px;
    padding-left: 15px;
  }

  select {
    width: 15.625rem;
  }

  #img-editor {
    margin-left: -30px;
  }

  .edit-user-btn {
    margin-left: -15px;
  }

  .edit-user-icon:hover {
    margin-left: 8.9375rem;
  }

  .mark-btn {
    margin-right: 0.3125rem;
  }

  #eye {
    margin: 1.6875rem 0 0 13.75rem;
  }

  .title {
    margin: 0;
  }
}
