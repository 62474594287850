/* stylelint-disable */
#search-container1 {
  font-family: sans-serif;
  text-align: center;
  flex-direction: column;
  align-items: center;
  background: #ece9a8;
  margin: 60px 100px;
  box-shadow: 5px 5px 13px #773c3c, -5px -5px 13px #5a5656;
  border-radius: 10px;
  height: fit-content;
  width: 600px;
  padding: 30px;
  display: none;
  position: fixed;
  z-index: 1;
  top: 50px;
  left: 400px;
}

.search-container {
  display: flex;
  flex-direction: column;
}

.dropdown {
  background-color: white;
  display: flex;
  flex-direction: column;
  border: 1px solid gray;
  margin-top: -30px;
  width: 290px;
  margin-left: 30px;
}

.dropdown:empty {
  border: none;
}

.dropdown-row {
  cursor: pointer;
  text-align: start;
  margin: 2px 0;
}

.search-input {
  width: 300px;
  height: 35px;
  border-radius: 4px;
  font-size: 17px;
  font-weight: 600;
}

.search-inner {
  margin: 30px;
}

.search-header {
  color: crimson;
}

.search-text {
  font-size: 20px;
  font-weight: bold;
  color: crimson;
  margin: -10px 0 0 110px;
  font-family: 'Gumela', sans-serif;
}

.search-text > p {
  margin: 15px;
  margin-bottom: 40px;
  border-bottom: 1px #773c3c dotted;
  width: fit-content;
}

#closeSearchIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 30px;
  color: crimson;
  cursor: pointer;
}

.pre-next-search {
  margin-left: 30%;
}

@media only screen and (max-width: 450px) {
  #search-container1 {
    font-family: sans-serif;
    text-align: center;
    flex-direction: column;
    align-items: center;
    margin: 60px 100px;
    box-shadow: 5px 5px 13px #773c3c, -5px -5px 13px #5a5656;
    border-radius: 10px;
    height: fit-content;
    width: 250px;
    padding: 30px;
    display: none;
    position: fixed;
    z-index: 1;
    top: 20px;
    left: -45px;
  }

  .search-header {
    font-size: 18px;
  }

  .search-input {
    width: 200px;
    height: 35px;
    border-radius: 4px;
    font-size: 17px;
    font-weight: 600;
    margin-left: -35px;
  }

  .dropdown {
    width: 200px;
  }

  #closeSearchIcon {
    font-size: 20px;
  }

  .search-text {
    margin: -10px 0 0 10px;
  }

  .search-text > p {
    font-size: 10px;
    margin: 20px 5px;
  }

  .pre-next-search {
    font-size: 12px;
    margin-left: 20%;
  }

  .pre-text-search {
    margin: 6px 10px;
    font-size: 12px;
  }

  .pre-text-search > span {
    margin: 0 5px;
  }
}
