@import url('https://fonts.googleapis.com/css2?family=Quintessential&display=swap');
/* stylelint-disable */

.marketer-zero-customer-container {
  border: 2px solid crimson;
  border-radius: 0.5rem;
  width: 56.375rem;
  height: fit-content;
  margin: 1.25rem 1.25rem 1.25rem 3.125rem;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 20px;
}

.header h1 {
  font-size: 20px;
}

#table {
  width: 100%;
}

#table,
#th,
#td {
  border: 1px solid crimson;
  border-collapse: collapse;
  width: 56.375rem;
  padding: 5px;
}

@media (max-width: 768px) {
  .marketer-zero-customer-container {
    border: 2px solid crimson;
    border-radius: 4px;
    width: 330px;
    height: fit-content;
    margin: 20px;
  }

  #table,
  #th,
  #td {
    width: 330px;
  }

  .header {
    margin: 10px;
  }

  .header h1 {
    font-size: 16px;
  }
}
