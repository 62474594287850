@import url('https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Neonderthaw&display=swap');

.text-center {
  text-align: center;
  font-family: 'Gumela', sans-serif;
  font-size: 20px;
  color: rgb(82, 16, 16);
}

.login-container {
  align-items: center;
  align-content: center;
}

.form-control-login {
  margin-top: 5px;
}

.login-btn {
  margin-right: auto;
  margin-left: auto;
}

.btn-login {
  width: 12.5rem;
  height: 3.125rem;
  padding: 10px;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: rgb(243, 19, 19);
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 5px 5px 13px #773c3c, -5px -5px 13px #d86565;
}

#login-eye {
  margin: -1.6825rem 0 0 29.375rem;
}

@media only screen and (max-width: 450px) {
  /* * {
    padding: 1px;
    margin: 1px;
  } */

  .form-control-login {
    width: 9.375rem;
    margin-left: -3px;
  }

  .login-form {
    display: flex;
    flex-direction: column;
    margin-left: 1.5625rem;
  }

  .btn1 {
    align-items: center;
    justify-content: center;
  }

  .btn-secondary1 {
    margin-left: 6px;
    width: 7.5rem;
  }

  #login-eye {
    margin: -1.6825rem 0 0 6.5625rem;
  }

  .btn-login {
    margin-left: -12px;
    margin-bottom: 20px;
  }

  .login-input {
    margin-left: -15px;
  }
}

.splash-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px;
  gap: 30px;
  box-shadow: 5px 5px 13px #773c3c, -5px -5px 13px #967777;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  padding: 30px;
  background: #fdf890;
  border-radius: 25px;
  height: 37.5rem;
}

.splash__image {
  margin-bottom: 100px;
}

.splash__image > img {
  height: 150px;
  width: 400px;
  position: absolute;
  top: 3.75rem;
  left: 35%;
  margin-top: 7.875rem;
  border-radius: 5px;
}

.splash-btn {
  width: 350px;
  font-size: 24px;
  box-shadow: 5px 5px 13px #363434, -5px -5px 13px #413e3e;
  height: 50px;
  padding: 10px;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: rgb(243, 19, 19);
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}

.splash-text {
  font-family: 'Neonderthaw', sans-serif;
  font-size: 30px;
  color: rgb(82, 16, 16);
  margin-top: 90px;
}

@media only screen and (max-width: 450px) {
  .splash__image > img {
    height: 100px;
    width: 150px;
    top: 150px;
    left: 105px;
  }

  .splash-btn {
    width: 150px;
    font-size: 17px;
    box-shadow: 5px 5px 13px #363434, -5px -5px 13px #413e3e;
    margin-left: 40px;
  }

  .splash-text {
    font-size: 20px;
  }

  .splash-container {
    width: 80%;
  }
}
