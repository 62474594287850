.landing_nav {
  /* background: #000; */
  background: transparent;
  align-items: center;
  justify-content: space-around;
  padding: 20px;

  /* margin-top: 40px; */
  display: flex;
  position: fixed; /* Make it stick/fixed */
  top: -50px; /* Hide the navbar 50 px outside of the top view */
  width: 100%; /* Full width */
  transition: top 0.3s; /* Transition effect when sliding down (and up) */
  z-index: 10;
}

.landing_image {
  width: 130px;
  height: 120px;
  margin-top: 20px;
}

.search_cont {
  border: 1px solid #fff;
  height: 60px;
  width: 60px;
  margin-right: 60px;
}

.landing_ul {
  display: flex;
  justify-content: space-between;
  padding: 3rem;
}

.landing_ul li {
  list-style: none;
  text-transform: none;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  margin-right: 40px;
}

.landing_ul li:hover {
  transform: scale(1.2);
}

.btn-nav {
  display: none;
}

.sign_btn {
  color: crimson;
}

.sign_btn_green {
  color: rgb(9, 126, 38);
}

.menuNav {
  overflow-y: scroll;
  list-style: none;
  position: fixed;
  background: #f25f7d;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 0;
  overflow: hidden;
  max-width: 350px;
  z-index: 9;
  display: none;
  margin: 40px -40px 0 0;
  transition: all 1s ease-in-out;
  padding-left: 30px;
}

#menu-link {
  display: block;
  padding: 20px 0;
  text-decoration: none;
  color: rgb(15, 15, 37);
  font-size: 19px;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 30px;

  /* animation: menu-link-animation 0.5s ease-in-out; */
}

@media screen and (max-width: 768px) {
  .search_cont {
    display: none;
  }

  .landing_ul {
    display: none;
  }

  .landing_nav {
    padding: 25px 15px 15px 15px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    background: #000;
  }

  .landing_image {
    width: 100px;
    height: 90px;
    padding-top: 20px;
  }

  .btn-nav {
    position: fixed;
    right: 20px;
    top: 12px;
    z-index: 10;
    cursor: pointer;
    background: transparent;
    border: none;
    font-weight: bold;
    color: var(--orange);
    outline: none;
    display: block;
  }

  .menuNav.showMenu {
    width: 100%;
  }
}
