.image-preview {
  border: solid white 2px;
}

.upload-container {
  display: flex;
  margin-right: 30px;
  padding-right: 40px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.art-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  font-family: 'Gumela', sans-serif;
  border-radius: 8px;
  margin: 10px 10px 10px 50px;
}

#file {
  width: 250px;
  height: 20px;
  overflow: hidden;
}

.upload-image {
  width: 250px;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 44px;
}

.upload-btn {
  margin-left: 4px;
  cursor: pointer;
  box-shadow: 5px 5px 13px #773c3c, -5px -5px 13px #d86565;
  border-radius: 5px;
  background-color: #f72727;
  color: #fff;
  outline: none;
  border: none;
  height: 25px;
  width: 250px;
}

@media only screen and (max-width: 450px) {
  .upload-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 200px;
    margin-left: 5px;
  }

  .upload-image {
    margin-left: 70px;
    width: 200px;
  }

  .upload-btn {
    width: 200px;
    margin-left: 25px;
  }

  #file {
    width: 200px;
    height: 20px;
    margin-left: 25px;
  }
}
