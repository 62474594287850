/* body.active-modal {
  overflow-y: hidden;
} */

.btn-modal {
  padding: 10px 20px;
  display: block;
  font-size: 18px;
}

.modal,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.overlay {
  background: rgba(49, 49, 49, 0.8);
}

.modal-content {
  position: absolute;
  top: 40%;
  left: 60%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: linear-gradient(45deg, #e4a5a5, #a15050);
  padding: 50px 28px;
  border-radius: 3px;
  max-width: 600px;
  min-width: 300px;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 4px;
  border: none;
  font-size: 20px;
  color: #000;
  font-weight: bold;
  background: #a45656;
}

@media only screen and (max-width: 600px) {
  .modal {
    width: 250px;
    margin: 20px;
  }

  .overlay {
    height: auto;
  }
}
