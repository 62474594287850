.containa {
  margin: 1.25rem 1.25rem 1.25rem 2.5rem;
  padding: 1.25rem;
  background-color: azure;
  border-radius: 0.5rem;
  text-align: center;
  text-decoration: none;
  height: 30rem;
  width: 59.375rem;
  display: flex;
  flex-direction: row;
}

.transaction {
  width: 55rem;
  margin: 1.25rem 1.25rem 1.25rem 5rem;
}

.tansaction-del-container {
  display: flex;
  gap: 30px;
}

.del-style-transact {
  width: 600px;
  height: 200px;
  border-radius: 8px;
  border: 2px solid crimson;
  background: rgb(243, 243, 99);
  margin: 100px 0 0 100px;
  display: none;
  z-index: 9;
  position: absolute;
  padding: 30px;
  text-align: center;
}

.del-style-transact > p {
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: bold;
  color: crimson;
}

.del-style-transact > button {
  margin: 30px;
  background-color: crimson;
  width: 100px;
  height: 40px;
  padding: 0.625rem;
  outline: none;
  border: none;
  border-radius: 0.3125rem;
  color: white;
  font-size: 1.125rem;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 5px 5px 13px #773c3c, -5px -5px 13px #d86565;
}

.del {
  margin-top: 20px;
}

.details-container {
  margin: 0 1.25rem 1.25rem 1.25rem;
  padding: 0 0.9375rem 0.9375rem 0.9375rem;
  background-color: azure;
  border-radius: 0.5rem;
  text-align: left;
  font-size: 1.25rem;
  font-weight: 600;
  font-family: 'Gumeland', cursive;
  box-sizing: border-box;
}

.cus-details {
  margin: 0 0 1.25rem 0;
  border-bottom: 1px solid #705050;
  color: crimson;
}

.cus-details > span {
  color: #49220b;
}

.image-container {
  padding: 0.25rem;
  gap: 1.875rem;
}

.edit {
  top: 0.625rem;
  right: 0.625rem;
  font-size: 1.875rem;
  color: #49220b;
  cursor: pointer;
  z-index: 1;
}

.cus-image {
  width: 18.75rem;
  height: 21.875rem;
  border-radius: 0.5rem;
  margin-top: 0.4rem;
}

#new-img-editor {
  text-align: center;
}

.view-trans {
  width: 9.5rem;
  height: 1.875rem;
  padding: 0.375rem;
  outline: none;
  border: none;
  border-radius: 0.3125rem;
  background-color: #f72727;
  color: #fff;
  font-size: 0.9375rem;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 5px 5px 13px #f7f1f1, -5px -5px 13px #e2dcdc;
}

.allTrans {
  margin-bottom: 1.25rem;
}

@media only screen and (max-width: 450px) {
  .containa {
    width: 20rem;
    gap: -1.25rem;
    display: flex;
    flex-direction: column;
    margin: 1.25rem;
  }

  .cus-food-cont {
    height: 46.875rem;
  }

  .details-container {
    width: 17.5rem;
    font-size: 0.9375rem;
    margin: 0;
    padding: 0 0.3125rem;
  }

  .cus-image {
    width: 15.625rem;
    height: 15.625rem;
  }

  #new-img-editor {
    text-align: center;
    margin-left: 0.9375rem;
  }

  .del-style-transact {
    width: 300px;
    margin: 200px 0 0 -10px;
  }

  .del-style-transact > button {
    margin: 20px;
    width: 50px;
    height: 30px;
    font-size: 15px;
  }

  .tansaction-del-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .transaction {
    height: 600px;
  }
}
