.details-container h2 {
  color: crimson;
}

.season-cont {
  margin: 20px 20px 20px 5rem;
}

@media only screen and (max-width: 450px) {
  .season-cont {
    height: 25rem;
    margin: 20px;
  }
}
