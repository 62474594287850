.total-orders {
  text-align: center;
  height: 40px;
  margin: 20px;
}

@media only screen and (max-width: 480px) {
  .total-orders {
    margin: 10px;
  }
}
