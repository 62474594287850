.user-container {
  height: auto;
  padding: 1.25rem;
  margin: 1.25rem 0 1.25rem 2.5rem;
  box-sizing: border-box;
}

.btns-container {
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;
  gap: 1.25rem;
}

.del-style {
  width: 600px;
  height: 200px;
  border-radius: 8px;
  border: 2px solid crimson;
  background: rgb(243, 243, 99);
  margin: 200px 0 0 500px;
  display: none;
  z-index: 1;
  position: absolute;
  padding: 30px;
  text-align: center;
}

.overlay1 {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: -1;
  background: rgba(49, 49, 49, 0.5);
}

.del-style > p {
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: bold;
  color: crimson;
}

.del-style > button {
  margin: 30px;
  background-color: crimson;
  width: 100px;
  height: 40px;
  padding: 0.625rem;
  outline: none;
  border: none;
  border-radius: 0.3125rem;
  color: white;
  font-size: 1.125rem;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 5px 5px 13px #773c3c, -5px -5px 13px #d86565;
}

@media only screen and (max-width: 450px) {
  .user-container {
    height: auto;
    width: 20rem;
    margin: 1.25rem;
  }

  .del-style {
    width: 300px;
    margin: 200px 0 0 30px;
    padding: 20px;
  }

  .del-style > button {
    margin: 20px;
    width: 50px;
    height: 30px;
    font-size: 15px;
  }

  .overlay1 {
    height: auto;
  }
}
