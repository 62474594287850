.url {
  background-color: aqua;
}

.table-head {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 20px 0;
}

.table-head h1 {
  font-size: 20px;
}

.table,
.th,
.td {
  border: 1px solid crimson;
  border-collapse: collapse;
  width: 56.375rem;
  padding: 5px;
  text-align: center;
}

@media (max-width: 768px) {
  .table,
  .th,
  .td {
    width: 320px;
    text-align: center;
  }

  .table-head h1 {
    font-size: 16px;
  }
}
