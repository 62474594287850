@import url('https://fonts.googleapis.com/css2?family=Quintessential&display=swap');

.items-to-costumer-main {
  display: flex;
  flex-direction: column;
  width: 56.25rem;
  gap: 5rem;
  font-family: 'Gumela', sans-serif;
  margin: 1.25rem 1.25rem 1.25rem 5rem;
  box-shadow: 5px 5px 13px #773c3c, -5px -5px 13px #967777;
  border-radius: 8px;
  align-content: center;
  justify-content: center;
  align-items: center;
  background: rgb(248, 244, 244);
}

.items-to-costumer {
  display: grid;
  grid-template-columns: 4fr 1.5fr;
  gap: 1.25rem;
  font-family: 'Gumela', sans-serif;
  border-radius: 8px;
  align-content: center;
  justify-content: center;
  align-items: center;
  background: rgb(248, 244, 244);
}

.products-container {
  display: flex;
  flex-direction: column;
  margin: 0;
  font-size: 20px;
  font-weight: bold;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-right: 5.625rem;
  text-align: center;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.product-name-price {
  font-size: 30px;
  font-weight: bold;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

.product-name-container {
  display: flex;
  flex-direction: column;
  margin: 10px;
  font-size: 30px;
  font-weight: bold;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.quantity-input {
  width: 80px;
  height: 25px;
  font-size: 14px;
  box-shadow: 5px 5px 13px #f7f1f1, -5px -5px 13px #e2dcdc;
  outline: none;
}

.quantity-pic-container {
  width: 31.25rem;
}

.pic-previous {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.qty-i-name {
  font-size: 30px;
  margin-bottom: 10px;
  font-family: 'Poppins', sans-serif;
}

.quantity-pic {
  width: 18.75rem;
  height: 18.75rem;
  border-radius: 5px;
}

.qunatity-price {
  font-size: 18px;
  font-weight: 400;
  font-style: italic;
  width: 6.25rem;
  text-align: center;
}

.input-add {
  display: flex;
  width: 11.25rem;
}

.input-add > li {
  margin: 5px;
}

.qty-desc {
  font-size: 16px;
  font-weight: 300;
  font-style: normal;

  /* width: 100px; */
}

.sum-item-btn {
  background-color: #00bcd4;
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
  font-size: 13px;
  font-weight: 300;
  padding-bottom: 10px;
  padding-top: 5px;
  text-align: center;
  width: 80px;
  height: 25px;
  outline: none;
  border: none;
  box-shadow: 5px 5px 13px #f7f1f1, -5px -5px 13px #e2dcdc;
}

.qty-sub-total {
  font-size: 16px;
  font-weight: 400;
  font-style: italic;
  margin: 0.5rem;
}

.qty-p-total {
  font-size: 20px;
  font-weight: 400;
  font-style: italic;
  margin: 0.5rem;
}

.finish-btn {
  width: 12.5rem;
  height: 1.875rem;
  padding: 6px;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: #f72727;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 5px 5px 13px #f7f1f1, -5px -5px 13px #e2dcdc;
}

.input-item-container {
  margin: 1.25rem;
  display: flex;
  gap: 1.25rem;
}

.input-item-container > input {
  width: 10rem;
}

.input-item-container > button {
  width: 6.25rem;
  height: 1.875rem;
  padding: 6px;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: #f72727;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 5px 5px 13px #f7f1f1, -5px -5px 13px #e2dcdc;
  margin-top: 0.3125rem;
}

@media only screen and (max-width: 450px) {
  .items-to-costumer {
    display: flex;
    flex-direction: column;
    width: 20rem;
    margin: 1.25rem;
  }

  .items-to-costumer-main {
    width: 20rem;
    gap: 2rem;
  }

  .products-container {
    margin: 0;
  }

  .product-name-price {
    font-size: 1.25rem;
  }

  .product-name-container {
    font-size: 1.25rem;
    width: 7.5rem;
  }

  .quantity-input {
    width: 3.125rem;
  }

  .quantity-pic-container {
    width: 20rem;
  }

  .quantity-pic {
    width: 12.5rem;
    height: 12.5rem;
  }

  .qunatity-price {
    font-size: 14px;
  }

  .input-add {
    width: 100%;
  }

  .input-add > li {
    margin: 5px;
  }

  .qty-desc {
    font-size: 14px;
  }

  .sum-item-btn {
    width: 100%;
    font-size: 10px;
  }

  .qty-p-total {
    font-size: 1rem;
  }

  .finish-btn {
    width: 9.375rem;
  }
}
