@import url('https://fonts.googleapis.com/css2?family=lato:whg@300;400;700&display=swap');

/* styling scrolling bars */

::-webkit-scrollbar {
  width: 10px;
  height: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #a5aaad;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #f5a5a5;
  border-radius: 10px;
}

:root {
  --primary-color: rgb(0, 35, 91);
  --secondary-color: rgb(255, 255, 255);
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a5aaad;
}

a:-webkit-any-link {
  text-decoration: none;
  color: #a5aaad;
  cursor: pointer;
}

/* general styling */

* {
  margin: 0;
  padding: 0;
}

body {
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
  height: 100vh;
  background-image:
    linear-gradient(
      321deg,
      rgba(240, 239, 233, 0.95),
      rgba(165, 165, 156, 0.95)
    ),
    url('../src/assets/image/foodforall.jpeg');
  background-size: auto;
  background-repeat: repeat;
  background-position: center;
}

.text-primary-p {
  color: #a5aaad;
  font-size: 14px;
  font-weight: 700;
}

.font-bold {
  font-weight: 700;
}

.text-title {
  color: #2e4a66;
}

.text-lightblue {
  color: #469cac;
}

.text-red {
  color: #cc3d38;
}

.text-yellow {
  color: #a98921;
}

.text-green {
  color: #3b9668;
}

.container {
  display: grid;
  height: 100vh;
  grid-template-columns: 0.8fr 2fr 1fr 1fr;
  grid-template-rows: 0.2fr 3fr;
  grid-template-areas:
    'sidebar nav nav nav'
    'sidebar main main main';
}

img {
  border-radius: 50%;
}

@media only screen and (max-width: 978px) {
  .container {
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas:
      'nav'
      'main';
  }
}
