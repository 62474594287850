.form-container {
  align-items: center;
  gap: 20px;
  font-family: 'Gumela', sans-serif;
  margin: 1.25rem 1.25rem 1.25rem 17.5rem;
  box-shadow: 5px 5px 13px #773c3c, -5px -5px 13px #967777;
  border-radius: 8px;
  background: #d3dad6;
}

.update-cus-container {
  padding-bottom: 20px;
}

.form-group {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.form-group-upload {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.title {
  font-size: 30px;
  font-weight: bold;
  color: #f72727;
  margin: 20px;
  text-align: center;
}

.p-text {
  color: rgb(26, 24, 24);
  margin: 0 0 10px -20px;
  text-align: center;
  font-weight: bold;
}

input {
  width: 31.25rem;
  height: 1.875rem;
  outline: none;
  box-shadow: 5px 5px 13px #773c3c, -5px -5px 13px #967777;
  border-radius: 3px;
  gap: 20px;
  cursor: pointer;
  padding-left: 5px;
  margin-top: 5px;
}

label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 10px;
  text-align: left;
  font-size: 22px;
  font-weight: 700;
}

::placeholder {
  color: rgb(71, 68, 68);
  padding-left: 5px;
  font-family: 'Gumela', sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.add-customer-btn {
  width: 12.5rem;
  height: 3.125rem;
  padding: 10px;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: #f72727;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 5px 5px 13px #773c3c, -5px -5px 13px #d86565;
}

.add-item-btn {
  background-color: #f88585;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 10px;
  padding-top: 5px;
  text-align: center;
  width: 31.25rem;
  height: 2.1875rem;
  margin: 1.875rem 0 1.875rem 1.875rem;
  outline: none;
  box-shadow: 5px 5px 13px #773c3c, -5px -5px 13px #967777;
  border: none;
}

.update-cus-button {
  margin-left: -40px;
}

.edit-cus-icon:hover {
  cursor: pointer;
  background: #fff;
  border: 1px solid #000;
  width: 50px;
  margin-left: 16.25rem;
  color: #ff899c;
  border-radius: 0.25rem;
}

@media only screen and (max-width: 450px) {
  input {
    width: 15.625rem;
  }

  .form-container {
    width: 20rem;
    margin: 1.25rem;
  }

  .edit-cus-cont {
    height: 62.25rem;
  }

  .form-group {
    margin: 0 0 0 1.25rem;
  }

  form {
    display: flex;
    flex-direction: column;
  }

  .btn1 {
    align-items: center;
    justify-content: center;
    margin-right: 35px;
  }

  .add-item-btn {
    width: 15.625rem;
    margin-left: 35px;
    margin-bottom: 20px;
  }

  .edit-cus-icon:hover {
    margin-left: 8.75rem;
  }

  .p-text {
    margin: 0 0 10px -45px;
  }

  .upload-image {
    margin-left: 5.5rem;
  }

  .edit-cs-btn {
    margin-left: 15px;
    width: 18.75rem;
  }

  .upload-container {
    margin-left: 20px;
  }
}
