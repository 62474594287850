.season-cont {
  padding: 20px;
}

.season-form {
  padding: 20px;
}

.season-form > label {
  margin-top: 2.5rem;
}

.end-date {
  font-size: 1.375rem;
  font-weight: bold;
  text-align: left;
  margin: 10px 0 0 15px;
  font-family: 'Gumela', sans-serif;
}

.season-btn {
  margin-left: -40px;
}

@media only screen and (max-width: 450px) {
  .season-btn {
    margin-left: -20px;
  }
}
