.trans-form {
  height: 600px;
  text-align: center;
  background: #f2f2f2;
}

.add-trans-btn {
  background-color: #f06a6a;
  width: 200px;
  height: 50px;
  padding: 10px;
  outline: none;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 5px 5px 13px #773c3c, -5px -5px 13px #d86565;
}

@media only screen and (max-width: 450px) {
  .trans-btn {
    width: 250px;
    margin-top: 30px;
  }

  .trans-form {
    margin: 20px;
    width: 320px;
  }

  .add-trans-btn {
    margin-left: -15px;
  }
}
