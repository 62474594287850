.form-container-item-list {
  width: 50rem;
  gap: 1.25rem;
  font-family: 'Gumela', sans-serif;
  margin: 1.25rem 1.25rem 1.25rem 7.5rem;
  box-shadow: 5px 5px 13px #773c3c, -5px -5px 13px #967777;
  border-radius: 8px;
  background: #f72727;
}

.item-name {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 4.375rem;
  margin: 1.25rem;
  align-items: center;
  align-content: center;
  text-align: center;
  padding: 20px;
  font-size: 1.5625rem;
  gap: 20px;
  font-family: 'Gumela', sans-serif;
  box-shadow: 5px 5px 13px #773c3c, -5px -5px 13px #967777;
  border-radius: 8px;
  list-style-type: none;
  background: rgb(235, 235, 58);
}

.item-item-name {
  text-align: left;
  color: black;
}

.item-name li img {
  margin-left: 1.875rem;
  margin-top: 8px;
}

.item-price {
  justify-self: end;
  color: black;
}

.no-product {
  border: 1px solid black;
  margin: 30px 0 0 100px;
  background: white;
  border-radius: 10px;
  height: 400px;
  width: 800px;
}

.no-product > p {
  margin-top: 60px;
}

.no-product-btn {
  margin: 1.25rem 0 1.25rem 17.875rem;
  background-color: crimson;
  width: 15.625rem;
  height: 3.125rem;
  padding: 0.625rem;
  outline: none;
  border: none;
  border-radius: 0.3125rem;
  color: white;
  font-size: 1.125rem;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 5px 5px 13px #773c3c, -5px -5px 13px #d86565;
}

@media only screen and (max-width: 450px) {
  .form-container-item-list {
    width: 20rem;
    margin: 1.25rem;
  }

  .item-name li img {
    margin-left: 0.625rem;
  }

  .item-name {
    font-size: 0.9375rem;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
  }

  .no-product {
    margin: 20px;
    height: 300px;
    width: 320px;
  }

  .no-product-btn {
    margin: 30px;
  }
}
